import './App.css';
import '@lottiefiles/lottie-player';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <lottie-player
          autoplay
          loop
          src="https://assets3.lottiefiles.com/packages/lf20_m9fz64i8.json"
          style={{ width: 800, maxWidth: '100%' }}
        />
      </header>
    </div>
  );
}

export default App;
